import React, { useState, useEffect } from "react"
import { TextInput, Box, Button, Select, Text } from "grommet"
import {
  format,
  setMinutes,
  setHours,
  setYear,
  setMonth,
  setDate,
  set,
  getDaysInMonth,
} from "date-fns"
import { useTranslation } from "react-i18next"

const DateTimePicker = ({ label = "Get Chart", dateObject, onClick }) => {
  const { t } = useTranslation()
  const [useDateObject, setDateObject] = useState(dateObject || new Date())
  const [year, setYearText] = useState(format(useDateObject, "yyyy"))
  const [month, setMonthText] = useState(format(useDateObject, "M"))
  const [day, setDayText] = useState(format(useDateObject, "d"))
  const [hour, setHour] = useState(format(useDateObject, "H"))
  const [min, setMin] = useState(format(useDateObject, "m"))

  useEffect(() => {
    let dateObj = new Date()
    dateObj = setYear(dateObj, year)
    dateObj = setMonth(dateObj, month - 1)
    dateObj = setDate(dateObj, day)
    dateObj = setHours(dateObj, hour)
    dateObj = setMinutes(dateObj, min)

    if (isNaN(dateObj.getTime())) {
      alert("invalid date")
    } else {
      setDateObject(dateObj)
    }
  }, [hour, min, year, month, day])

  return (
    <>
      <Box
        direction="row"
        pad={{
          bottom: "small",
          top: "small",
        }}
      >
        <Select
          value={year}
          options={[...Array(120).keys()].map(e => `${1911 + e}`).reverse()}
          onChange={({ option }) => setYearText(option)}
        />

        <Select
          valueKey={{ key: "value", reduce: true }}
          onChange={({ value }) => setMonthText(value)}
          value={month}
          labelKey="label"
          options={[...Array(12).keys()].map(e => {
            return {
              value: `${1 + e}`,
              label: t(`month.${1 + e}`),
            }
          })}
        />
        <Select
          onChange={({ option }) => setDayText(option)}
          value={day}
          options={[...Array(getDaysInMonth(useDateObject)).keys()].map(
            e => `${1 + e}`
          )}
        />
      </Box>

      <Box
        direction="row"
        justify="center"
        alignContent="center"
        pad={{
          bottom: "small",
          top: "small",
        }}
      >
        <Select
          valueKey={{ key: "value", reduce: true }}
          labelKey="label"
          onChange={({ value }) => setHour(value)}
          value={hour}
          options={[...Array(24).keys()].map(e => {
            return {
              value: `${e}`,
              label: `${e}`.padStart(2, "0"),
            }
          })}
        />
        <Select
          valueKey={{ key: "value", reduce: true }}
          labelKey="label"
          onChange={({ value }) => setMin(value)}
          value={min}
          options={[...Array(60).keys()].map(e => {
            return {
              value: `${e}`,
              label: `${e}`.padStart(2, "0"),
            }
          })}
        />
      </Box>
      <Box align="end">
        <Button
          onClick={() => {
            onClick &&
              onClick(
                set(useDateObject, {
                  seconds: 0,
                  milliseconds: 0,
                })
              )
          }}
          label={label}
        />
      </Box>
    </>
  )
}

export default DateTimePicker
